<template>
  <div class="container mt-4" v-if="loaded">
    <div class="mb-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb fs-3">
          <li class="breadcrumb-item"><a href="/products">Products</a></li>
          <li class="breadcrumb-item active">
            {{ displayProductBreadcrumbsActionLabel }}
          </li>
          <li
            v-if="actionlabel == 'Edit'"
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ productName }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3">
            <p class="h2">{{ displayProductBreadcrumbsActionLabel }}</p>
            <div>
              <label class="form-label mt-3">Product Name:</label>
              <input
                :class="[
                  'form-control',
                  this.formErrors.productname ? 'is-invalid' : '',
                ]"
                ref="productname"
                v-model="product.name"
                @blur="validate('productname')"
              />
              <div class="invalid-feedback">
                <small>{{ this.productnameerrormessage }}</small>
              </div>
            </div>
            <div>
              <label class="form-label mt-3">Product Url:</label>
              <input
                :class="[
                  'form-control',
                  this.formErrors.producturl ? 'is-invalid' : '',
                ]"
                ref="producturl"
                v-model="product.url"
                required
                @blur="validate('producturl')"
              />
              <div class="invalid-feedback">
                <small>{{ this.producturlerrormessage }}</small>
              </div>
            </div>
            <div>
              <label class="form-label mt-3">Description:</label>
              <textarea
                :class="[
                  'form-control',
                  'description',
                  this.formErrors.description ? 'is-invalid' : '',
                ]"
                ref="description"
                v-model="product.description"
                required
                @blur="validate('description')"
                placeholder=""
              ></textarea>
              <div class="invalid-feedback">
                <small>Please enter a description</small>
              </div>
              <div>
                <small>{{ this.descriptionRemaining }} characters left</small>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2">
            <label class="form-label mt-3">Image:</label>
            <drag-and-drop-upload-card
              v-model="productImage"
              :label="`product image`"
              :helpText="`Image will be resized to fit 120px high by 240px wide.`"
              :contentTypes="['png', 'jpg', 'jpeg', 'gif', 'svg']"
              :inlinePreview="true"
              :uniqueId="`product-image`"
              :uploadTo="`images`"
              :resizeImage="false"
              :displayHeight="120"
            ></drag-and-drop-upload-card>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" v-if="canFeatureProduct">
      <div class="card-body">
        <h2>Want to feature this product?</h2>
        <div v-for="site in availableFeatureSites" :key="`feature-${site}`">
          <a href="#" @click.prevent="featureProduct(site)">
            Feature product on the {{ getSite(site) }}
          </a>
        </div>
      </div>
    </div>
    <div class="card mb-3" v-if="isProductFeatured">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h2>Adjust Featured Product</h2>
            <ul class="list-unstyled">
              <li
                v-for="site in product.featuredProductSites"
                :key="`unfeature-${site}`"
              >
                <a href="#" @click.prevent="unfeatureProduct(site)">
                  Unfeature product on the {{ getSite(site) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <p class="h2">Product Categories</p>
            <SiteSelectList
              :class="[
                'form-control',
                'category',
                selectedCategories.length == 0 && formErrors.category
                  ? 'is-invalid'
                  : '',
              ]"
              ref="category"
              :content-remaining="null"
              :max-categories="maxCategories"
              v-model="product.categories"
              @blur="validate('category')"
            ></SiteSelectList>

            <div class="invalid-feedback">
              <small>{{ productcategoryerrormessage }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="d-inline me-3">
        <button
          @click="clickaction()"
          :class="[
            'btn btn-primary text-white text-decoration-none',
            enabled ? '' : 'disabled',
          ]"
        >
          {{ this.displayCategoryActionLabel }}
        </button>
      </div>
      <div class="d-inline">
        <router-link
          to="/products"
          :class="['btn btn-outline', enabled ? '' : 'disabled']"
        >
          Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.description {
  width: 100%;
  height: 260px;
  resize: none;
}
</style>

<script>
import ProductService from "../../services/ProductService";
import TrackingService from "../../services/TrackingService";
import DragAndDropUploadCard from "../../components/Content/Upload/DragAndDropUploadCard.vue";
import SiteSelectList from "../../components/Content/Upload/SiteSelectList.vue";

export default {
  name: "addproduct",
  components: {
    DragAndDropUploadCard,
    SiteSelectList,
  },
  data: () => {
    return {
      product: {},
      featured: [],
      productName: "",
      maxCategories: {},
      productImage: null,
      //old props
      productIDEdit: 0,
      contentRemaining: null,
      categories: {},
      loaded: false,
      actionlabel: "Add",
      description: "",
      descriptioncounter: " Characters Left",
      maxlength: 1500,
      formErrors: {},
      invalid: true,
      serverName: "",
      enabled: true,
      savedCategory: false,
      productnameerrormessage: "Please enter a product name",
      producturlerrormessage: "Please enter a product url",
      productcategoryerrormessage: "Please select at least one category",
    };
  },
  methods: {
    getSite(siteId) {
      try {
        return [...this.$auth.activeCompany.sites].filter(
          (s) => s.siteID == siteId
        )[0].siteName;
      } catch {
        return "Couldn't get site name.";
      }
    },

    async getProduct() {
      let product = await ProductService.getProduct(this.productIDEdit);
      let categories = product.categories;
      Object.keys(categories).forEach((k) => {
        categories[k].map((o) => (o.saved = true));
      });
      product.categories = categories;
      this.product = product;

      if (product.imageUrl && product.imageUrl !== "") {
        this.productImage = {
          clientName: product.imageUrl,
          serverName: product.imageUrl,
          baseUrl: product.baseImageUrl + product.imageUrl,
          sasToken: "",
        };
        this.serverName = product.imageUrl;
      }
    },

    validate(item) {
      if (item === "all" || item === "category") {
        if (this.selectedCategories.length == 0) {
          this.$set(this.formErrors, "category", true);
        } else {
          this.$set(this.formErrors, "category", false);
        }
      }
      if (item === "all" || item === "productname") {
        if (this.product.name == "" || this.product.name == null) {
          this.$set(this.formErrors, "productname", true);
        } else {
          this.$set(this.formErrors, "productname", false);
        }
      }
      if (item === "all" || item === "description") {
        if (
          this.product.description == "" ||
          this.product.description == null
        ) {
          this.$set(this.formErrors, "description", true);
        } else {
          this.$set(this.formErrors, "description", false);
        }
      }
      if (item === "all" || item === "producturl") {
        if (!this.validUrl(this.product.url)) {
          // specific url error
          this.producturlerrormessage = "Please enter a valid product url";
          this.$set(this.formErrors, "producturl", true);
        } else if (this.product.url == "" || this.product.url == null) {
          this.$set(this.formErrors, "producturl", true);
        } else {
          this.$set(this.formErrors, "producturl", false);
          // put back to default error message
          this.producturlerrormessage = "Please enter a product url";
        }
      }
    },

    validUrl(url) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return pattern.test(url);
    },

    async clickaction() {
      this.validate("all");
      let formHasErrors = Object.values(this.formErrors).some((i) => i);
      if (formHasErrors) {
        return;
      } else {
        this.enabled = false;
        if (this.actionlabel == "Edit") {
          await this.saveProduct();
        } else {
          await this.addProduct();
        }
        this.$router.push({
          name: "products",
          params: {
            alert: this.displayPostSuccessMessage,
          },
        });
      }
    },

    clearForm() {
      // clear our form
      this.$set(this.product, "description", "");
      this.$set(this.product, "name", "");
      this.$set(this.product, "url", "");
      this.$set(this.product, "id", null);
      this.$set(this.product, "ordinalPosition", 0);
      this.$set(this.product, "imageId", "0");
      this.$set(this.product, "categories", {});
      this.$set(this.product, "featuredProductSites", []);
      this.$set(this.product, "allFeaturedProductSites", []);
    },

    async addProduct() {
      let company = this.$auth.activeCompany;
      // build product title DTO
      let ProductByNameDTO = {
        company: company,
        productname: this.product.name,
      };

      // check if product title exists
      let postProductNameResult = await ProductService.getProductByName(
        ProductByNameDTO
      );
      if (postProductNameResult.length > 0) {
        // Product name exists
        this.$set(this.formErrors, "productname", true);
        this.productnameerrormessage =
          "Selected product name already exists.  Please enter a different product name.";
      }
      // no errors, build product DTO to post (same DTO for "Add" and "Save")
      let ProductSaveDTO = {
        company: company,
        productname: this.product.name,
        producturl: this.product.url,
        description: this.product.description,
        categoryids: this.selectedCategories,
        updateonly: false,
      };

      if (this.productImage && this.productImage.serverName) {
        ProductSaveDTO.imageID = "0";
        ProductSaveDTO.FileName = this.productImage.serverName;
      }

      TrackingService.track("add new product", {
        detail: ProductSaveDTO.productname,
      });

      try {
        this.val = true;
        await ProductService.addProduct(ProductSaveDTO);
      } catch (e) {
        console.log(e);
      }
    },
    async saveProduct() {
      let company = this.$auth.activeCompany;

      let ProductSaveDTO = {
        company: company,
        productname: this.product.name,
        producturl: this.product.url,
        description: this.product.description,
        productid: this.product.id,
        categoryids: this.selectedCategories,
        siteids: this.product.allFeaturedProductSites,
        featuredProductSiteids: this.product.featuredProductSites,
        ordinalPosition: this.product.ordinalPosition,
        updateonly: true,
      };

      if (this.productImage && this.productImage.serverName) {
        ProductSaveDTO.imageID = this.product.imageId;
        ProductSaveDTO.FileName = this.productImage.serverName;
      }
      try {
        TrackingService.track("update product", {
          detail: ProductSaveDTO.productname,
        });

        await ProductService.saveProduct(ProductSaveDTO);
      } catch (e) {
        console.log(e);
      }
    },

    featureProduct(siteId) {
      this.product.featuredProductSites.push(siteId);
    },

    unfeatureProduct(siteId) {
      this.product.featuredProductSites.splice(
        this.product.featuredProductSites.indexOf(siteId),
        1
      );
    },
  },
  computed: {
    selectedCategories() {
      let list = Object.values(this.product.categories).flat();
      let childIdList = [];
      for (let item of list) {
        if (item.child) {
          childIdList.push(item.child.categoryid);
        } else if (item.parent) {
          childIdList.push(item.parent.categoryid);
        }
      }
      return childIdList;
    },
    displayProductBreadcrumbsActionLabel() {
      return this.actionlabel == "Add" ? "Add Product" : "Edit Product";
    },
    displayCategoryActionLabel() {
      return this.actionlabel == `Edit` ? "Save Product" : "Add Free Product";
    },
    displayPostSuccessMessage() {
      return this.actionlabel == `Edit`
        ? "Your product has been saved."
        : "Your product has been added.";
    },
    descriptionRemaining() {
      return this.maxlength - this.product.description.length;
    },
    availableFeatureSites() {
      return this.product.allFeaturedProductSites.filter(
        (s) => !this.product.featuredProductSites.includes(s)
      );
    },
    anyUnfeaturedSites() {
      return (
        [...this.$auth.activeCompany.sites].filter(
          (s) => !this.product.featuredProductSites.includes(s.siteID)
        ).length > 0
      );
    },
    isProductFeatured() {
      return this.product.featuredProductSites.length > 0;
    },

    canFeatureProduct() {
      return this.availableFeatureSites.length > 0;
    },
  },
  async mounted() {
    this.clearForm();

    if (this.$route.query.product) {
      this.productIDEdit = this.$route.query.product;
      await this.getProduct();
      this.actionlabel = "Edit";
      this.productid = this.$route.query.product;
      this.productName = this.product.name;

      TrackingService.track("open product for editing", {
        detail: this.productName,
      });
    } else {
      TrackingService.track("start adding new product");
    }

    let sites = [...this.$auth.activeCompany.sites];
    let obj = {};
    for (let i = 0; i < sites.length; i++) {
      obj[sites[i].siteID] = 1;
    }
    this.maxCategories = obj;
    this.formErrors.categoryid = false;
    this.loaded = true;
  },
};
</script>
