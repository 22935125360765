import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getProductSites(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/GetProductSites",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getProductCategories(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/GetProductCategories",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getProductSiteCategory(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/GetProductSiteCategory",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getFeaturedProducts(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/GetFeaturedProducts",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getProduct(productId) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/Products/GetProduct/${productId}`,
      authHeaders
    );
    return res.data;
  },
  async getProducts(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/GetProducts", filter, authHeaders);
    return res.data;
  },
  async getProductByName(filter) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/GetProductByName",
      filter,
      authHeaders
    );
    return res.data;
  },
  async uploadProductImage(upload) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/Upload", upload, authHeaders);
    return res.data;
  },
  async addProduct(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/Add", order, authHeaders);
    return res.data;
  },
  async deleteProduct(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/Delete", order, authHeaders);
    return res.data;
  },
  async deleteImage(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/DeleteImage", order, authHeaders);
    return res.data;
  },
  async saveProduct(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Products/Save", order, authHeaders);
    return res.data;
  },
  async saveProductOrder(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Products/SaveProductOrder",
      order,
      authHeaders
    );
    return res.data;
  },
};
